// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";

// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDauuumUwS7XIv6k2hc5E3-tdQZz-zdDvM",
    authDomain: "family-tree-45c19.firebaseapp.com",
    projectId: "family-tree-45c19",
    storageBucket: "family-tree-45c19.appspot.com",
    messagingSenderId: "668990120589",
    appId: "1:668990120589:web:6ff608269600b35425f8c4",
    measurementId: "G-RV9QTW35HV"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


// Auth Test 
export const auth = getAuth();


console.log('this page is running')



