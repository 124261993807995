import { MantineProvider, MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "dark",
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        globalStyles: (theme) => ({
          "*, *::before, *::after": {
            boxSizing: "border-box",
          },

          body: {
            ...theme.fn.fontStyles(),
            // backgroundColor: "#C5B095",
            margin: 0,
            padding: 0,
            overflow: "hidden",
          },

          // ".your-class": {
          //   backgroundColor: "red",
          // },

          // "#your-id > [data-active]": {
          //   backgroundColor: "pink",
          // },
        }),
      }}
    >
      {children}
    </MantineProvider>
  );
}
