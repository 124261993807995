import ChangeEvent from "react";

import { Center, Image, TextInput, Box } from "@mantine/core";
import crest from "../assets/crest_cutout.png";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../lib/firebase";
import { toast } from "react-hot-toast";

const inputStyle = {
  input: {
    "&:focus-within": {
      borderColor: "#B88139",
      margin: "0",
    },
  },
};

export default function Splash() {
  return (
    <>
      <Center
        sx={{
          height: "100vh",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "#C5B095",
        }}
      >
        <Box
          w={"40vw"}
          maw={"500px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Image src={crest} width={"100%"} p={0} mb={"2rem"} />
          <Form />
        </Box>
      </Center>
    </>
  );
}

const Form = () => {
  const [value, setvalue] = useState("");

  function changeHandler(e: any) {
    setvalue(e.target.value);
  }
  function submitHandler(e: any) {
    e.preventDefault();
    console.log("Logging in");
    signInWithEmailAndPassword(auth, "info@kassemyounis.com", value)
      .then((userCredential) => {
        toast.success("Signed in!");
      })
      .catch((error) => {
        toast.error("Wrong password");
      });

    setvalue("");
  }

  return (
    <form onSubmit={submitHandler} style={{ width: "80%" }}>
      <TextInput
        value={value}
        onChange={changeHandler}
        size="md"
        placeholder={"Password"}
        styles={(theme) => inputStyle}
      />
    </form>
  );
};
