import { ThemeProvider } from "./ThemeProvider";
import Splash from "./components/Splash";
import Tree from "./components/Tree";
import { Toaster } from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./lib/firebase";

export default function App() {
  const [user] = useAuthState(auth);

  return (
    <ThemeProvider>
      {!user ? <Splash /> : <Tree />}
      <Toaster />
    </ThemeProvider>
  );
}
