import { Center, Button, Grid } from "@mantine/core";
import {
  TransformWrapper,
  TransformComponent,
} from "@pronestor/react-zoom-pan-pinch";
import { BiZoomIn, BiZoomOut, BiReset } from "react-icons/bi";
// For testing purposes only
import { auth } from "../lib/firebase";
import { signOut } from "firebase/auth";

export default function Tree() {
  return (
    <>
      <Center
        sx={{
          backgroundColor: "#EADFCB",
          height: "100vh",
        }}
      >
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
            <>
              <Grid pos={"absolute"} top={20} right={20} sx={{ zIndex: 999 }}>
                <Grid.Col span={4}>
                  <Button onClick={() => zoomIn()}>
                    <BiZoomIn size={"1.5em"} />
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button onClick={() => zoomOut()}>
                    <BiZoomOut size={"1.5em"} />
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button onClick={() => resetTransform()}>
                    <BiReset size={"1.5em"} />
                  </Button>
                </Grid.Col>
              </Grid>
              <Button
                sx={{ zIndex: 999 }}
                pos={"absolute"}
                bottom={20}
                right={20}
                onClick={() => signOut(auth)}
                color="red"
              >
                Sign Out
              </Button>
              <TransformComponent
                wrapperStyle={{
                  height: "100vh",
                  width: "100vw",
                  //   overflow: "scroll",
                }}
                // contentStyle={{}}
              >
                <img
                  //   style={{ width: "3815px", height: "4082px" }}
                  src="https://drive.google.com/uc?id=1VVxk2mqIDhmUTMeS0sp5txf9GxkzSU4e"
                  alt="test"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>{" "}
      </Center>
    </>
  );
}
